<template>
  <div class="commit-agreement">
    <v-row>
      <v-col>
        <h1 class="mb-5">Summer Of Joy Pledge</h1>
      </v-col>
    </v-row>
    <v-row class="text-left">
      <ul>
        <li>I commit to spreading joy, even when confronted with its opposite</li>
        <li>I commit to creating joy, even when I can't see it</li>
        <li>I commit to being joy, even when the world would have me be otherwise</li>
      </ul>
    </v-row>
    <v-row justify="center">
      <v-col class="text-center">
        <v-checkbox color="#F79387" v-model="agreed" label="I accept the DYP challenge" />
      </v-col>
    </v-row>
    <v-row class="mt-10" justify="center">
      <v-col cols="6">
        <v-btn color="#84C9C8" :disabled="!agreed" block dark x-large rounded @click="next">Next</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CommitAgreement',

  data() {
    return {
      agreed: false,
    }
  },

  methods: {
    next() {
      this.$router.push({ name: 'CharitySelector' })
    }
  }
}
</script>

<style lang="scss">
.commit-agreement {
  li {
    margin-bottom: 10px;
  }
}
</style>