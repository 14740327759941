<template>
  <div class="participant-form">
    <v-row>
      <v-col>
        <h1 class="mb-5">What's Your Name?</h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="signup">
      <v-row>
        <v-col cols="12">
          <v-text-field outlined v-model="firstName" label="First Name" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field outlined v-model="lastName" label="Last Name" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field outlined type="tel" @input="enforcePhoneFormat" maxlength="14" v-model="phoneNumber" label="Phone Number" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field outlined type="email" v-model="email" label="Email" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>*Please use the email address you use at either LiveMetta or Ra Yoga so that we can connect your DYP information to your existing account.</p>
        </v-col>
      </v-row>
      <v-row v-if="error">
        <v-col>
          <v-alert type="error" dismissible>
            {{ error }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-btn color="#C2C1BF" block dark x-large rounded @click="$router.go(-1)">Back</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn color="#84C9C8" :loading="isCreatePending" :disabled="!canSubmit || isCreatePending" block dark x-large rounded type="submit">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ParticipantForm',

  data() {
    return {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      participants: 'participant/list',
    }),

    participant() {
      return this.participants[0]
    },
    isCreatePending() {
      return this.$store.state.participant.isCreatePending
    },
    canSubmit() {
      return (this.email !== null && this.firstName !== null && this.lastName !== null && this.phoneNumber !== null && !this.isCreatePending)
    }
  },

  methods: {
    async signup() {
      await this.$store.dispatch('participant/create', {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
        email: this.email,
      }).then(() => {
        this.$store.state.firstName = this.firstName
        this.$store.state.lastName = this.lastName
        this.$store.state.phoneNumber = this.phoneNumber
        this.$store.state.email = this.email
        this.$router.push({ name: 'AgreementForm' })
      }).catch(err => {
        if (err == 'BadRequest: Validation error') {
          this.error = 'It looks like someone has already signed up with that phone number or email address! Try logging in!'
        } else {
          this.error = 'Something went wrong! Please try signing up again. If you continue to have issue please contact info@rayoga.com'
        }
      })
    },

    enforcePhoneFormat() {
      let x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    }
  },

  beforeMount() {
    if (this.participant.id !== undefined) {
      this.$router.push({ name: 'AgreementForm'})
    }
  }
}
</script>

<style lang="scss">

</style>